import * as React from 'react';
import { fromPairs, compact, isNil } from 'lodash/fp';
import { get, isEmpty, isEqual, isUndefined } from 'lodash';
import { option } from 'fp-ts';
import { getOrElse } from 'fp-ts/lib/Option';
import { StylesProvider } from '@material-ui/core';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { TopNavbar } from 'src/components/TopNavbar/TopNavbar';
import AssortmentScopeSelector from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.container';
import Headerbar from 'src/components/Headerbar/Headerbar.container';
import RightContainer from 'src/components/RightContainer/RightContainer.container';
import { Route, Routes, Navigate } from 'react-router-dom';
import styles, { viewPortStyle } from './NavigationShell.styles';
import serviceContainer from 'src/ServiceContainer';

import {
  BoundTenant,
  BoundTab,
  BoundSection,
  BoundView,
  TenantTab,
  BoundEnabledPerspective,
} from 'src/services/configuration/codecs/bindings.types';
import { RouteLink } from 'src/types/RouteLink';
import {
  computeTabRouteLinks,
  computeViewRouteLinks,
  computePerspectiveUiRouteSuffix,
  extractNavPath,
} from 'src/pages/NavigationShell/navigationUtils';
import { PrintProps } from 'src/components/higherOrder/Print/Print';

import { Props as ConnectProps } from './NavigationShell.container';
import { RouterProps } from 'react-router';

import { zipper } from 'src/pages/NavigationShell/NavigationZipper';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { pipe } from 'fp-ts/lib/function';
import { create } from 'jss';
import {
  getActiveComponentOverflow,
  getActivePerspective,
  getActivePerspectiveId,
  getContentArea,
} from './NavigationShell.utils';
import SideNav from 'src/components/Sidenav/Sidenav.container';
import { findPerspective, getPerspectiveLookupKey } from 'src/services/configuration/service';
import MfpScopeSelector from 'src/components/Mfp/MfpScopeSelector/MfpScopeSelector';
import MfpScopebar from 'src/components/Mfp/MfpScopeSelector/MfpScopebar';
import { getScope } from 'src/state/scope/Scope.actions';

const jss = create({
  ...jssPreset(),
});

const generateClassName = createGenerateClassName({
  seed: 's5',
});

export const ASSORTMENT_ROUTE_PATH = '/:perspective/:tab?/:section?/:page?/:subPage?';

export interface Props extends PrintProps, RouterProps, ConnectProps { }

export interface Bindings {
  [perspective: string]: BoundTenant;
}
interface State {
  currentTab?: string;
}

export default () => {
  return class NavigationShell extends React.Component<Props, State> {
    readonly perspectives: BoundEnabledPerspective[];
    readonly bindings: Bindings;
    readonly tabRouteLinks: { [perspective: string]: RouteLink[] };

    constructor(props: Props) {
      super(props);
      // Depending on if the UiConf is Assortment or Configurator, switch between them
      const config = serviceContainer.configService;
      // These must be done in the constructor, because at factory invocation time
      // The config probably hasn't been request
      this.perspectives = config.getEnabledPerspectives();
      this.bindings = fromPairs(
        this.perspectives.map((perspective) => {
          const perspectiveLookupKey = getPerspectiveLookupKey(perspective);
          return [perspectiveLookupKey, config.getBindings(perspective)];
        })
      );
      // Compute all of the route links for each tab
      this.tabRouteLinks = fromPairs(
        compact(
          this.perspectives.map((perspective) => {
            const perspectiveLookupKey = getPerspectiveLookupKey(perspective);
            return this.bindings[perspectiveLookupKey]
              ? [perspectiveLookupKey, computeTabRouteLinks(this.bindings[perspectiveLookupKey])]
              : undefined;
          })
        )
      );
      this.setPerspective = this.setPerspective.bind(this);
      this.isPerspectiveDefined() && this.setPerspective();
      const currentTab = get(props, 'routerParams.tab');

      // If first tab has flow status config, apply it
      if (props.tenantConfig && currentTab) {
        const tab = (this.props.tenantConfig.tabs as TenantTab[]).find((x) => x.pathSlot == currentTab);
        if (tab && tab.flowStatusOverride && tab.flowStatusOverride.values) {
          props.updateFlowStatusConfig(tab.flowStatusOverride);
          props.updateFlowStatus(tab.flowStatusOverride.defaultSelection);
        }
      }

      this.state = {
        currentTab,
      };
    }

    componentDidMount() {
      if (this.props.routerLocation) {
        this.props.setActivePage(this.props.routerLocation.pathname);
        const currentSubPage: string = get(this, 'props.routerParams.subPage', '');
        if (currentSubPage && currentSubPage.length > 0) {
          this.props.setActiveSubPage(currentSubPage);
        }
        const currentTab: string = get(this, 'props.routerParams.tab', '');
        if (currentTab && currentTab.length > 0) {
          this.props.setActiveTab(currentTab);
        }
      }
      // SPIKE: should this go somewhere else?
      this.props.onMainMount();
    }

    setPerspective() {
      const selectedPerspective: BoundEnabledPerspective | null =
        this.perspectives.filter((p) => p.pathSlot === this.props.routerParams.perspective)[0] || null;

        // send the tab in at the same time as the persp, as mfp scope needs both at the same time
      this.props.setActivePerspective(selectedPerspective, this.props.routerParams.tab);

      const perspectiveLookupKey = getPerspectiveLookupKey(selectedPerspective);
      const confdefnLookUp = findPerspective(this.props.confdefns, perspectiveLookupKey)
      const activeTenant = confdefnLookUp ? confdefnLookUp.tenant : undefined;
      this.props.updateAppConfig(activeTenant)
      const boundTenant = this.bindings[perspectiveLookupKey];

      this.props.setViewLinks(computeViewRouteLinks(boundTenant));
    }

    isPerspectiveDefined() {
      // If perspective is not defined or improper, send them back to perspective selection
      const id = pipe(
        getActivePerspectiveId(this.props.routerParams.perspective, this.perspectives),
        getOrElse(() => '')
      );
      if (!this.bindings[id]) {
        window.location.hash = '/';
        return false;
      }
      return true;
    }

    componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<Record<string, any>>) {
      const { mfpScopeId } = this.props;

      const currentTab: string = get(this, 'props.routerParams.tab', '');
      const oldPaths = option.toUndefined(extractNavPath(prevProps.routerLocation));
      const newPaths = option.toUndefined(extractNavPath(this.props.routerLocation));

      if (this.props.routerLocation.pathname !== prevProps.routerLocation.pathname && newPaths) {
        if (isNil(oldPaths) || !isEqual(oldPaths[4], newPaths[4])) {
          this.props.setActiveSubPage(newPaths[4]);
        }
        if (isNil(oldPaths) || !isEqual(oldPaths.slice(0, 4), newPaths.slice(0, 4))) {
          this.props.setActivePage(this.props.routerLocation.pathname);
        }
      }

      if (this.state.currentTab !== currentTab || (this.props.activeTab === '' && this.state.currentTab !== '')) {
        this.props.setActiveTab(currentTab);
        // Check if old tab had a flowstatus config, and if new one has one
        const oldTab = (this.props.tenantConfig.tabs as TenantTab[]).find((x) => x.pathSlot == this.state.currentTab);
        const tab = (this.props.tenantConfig.tabs as TenantTab[]).find((x) => x.pathSlot == currentTab);
        if (tab && tab.flowStatusOverride && tab.flowStatusOverride.values) {
          this.props.updateFlowStatusConfig(tab.flowStatusOverride);
          this.props.updateFlowStatus(tab.flowStatusOverride.defaultSelection);
        } else if (oldTab && oldTab.flowStatusOverride && oldTab.flowStatusOverride.values) {
          this.props.updateFlowStatusConfig(this.props.tenantConfig.flowStatus);
          this.props.updateFlowStatus(this.props.tenantConfig.flowStatus.defaultSelection);
        }
        this.setState({
          currentTab,
        });
      }

      // If Url is incomplete, autocomplete it
      if (this.isPerspectiveDefined()) {
        const [perspective, tab, section, view] = window.location.hash.split('/').slice(1);
        const viewWithSearch = view && view.split('?')[0];
        const id = pipe(
          getActivePerspectiveId(this.props.routerParams.perspective, this.perspectives),
          getOrElse(() => '')
        );
        const boundTenant = this.bindings[id];

        const maybeTab = boundTenant.boundTabs.find((x: BoundTab) => x.pathSlot === tab);
        const maybeSection =
          maybeTab &&
          maybeTab.boundSections &&
          maybeTab.boundSections.find((x: BoundSection) => x.pathSlot === section);
        const maybeView =
          maybeSection && maybeSection?.boundViews.find((x: BoundView) => x.pathSlot === viewWithSearch);
        if (!maybeTab || !maybeSection || !maybeView) {
          window.location.hash = computePerspectiveUiRouteSuffix(boundTenant);
        }
        if (perspective !== this.props.enabledPerspective?.pathSlot) {
          window.location.hash = computePerspectiveUiRouteSuffix(this.bindings[this.props.enabledPerspective?.pathSlot!]);
        }
      }

      // mfp stuff
      const [search, setSearch] = this.props.routerSearch;
      const queryParamScopeId = search.get('scope');
      // if we have a scopeId but no query param scopeId, set it
      if (mfpScopeId && mfpScopeId !== queryParamScopeId && !this.props.isFetchingMfpScope) {
        // if we have a scopeId but it's no in the queryParams, set it there
        search.set('scope', mfpScopeId);
        setSearch(search);
      }
      // if we have a query param scopeid but not scopeid, try and fetch the scope with the query param id
      if (!mfpScopeId && queryParamScopeId && typeof queryParamScopeId === 'string' && !this.props.isFetchingMfpScope) {
        // if we have a queryParam scopeId but not a state.scopeId, try to get the scope of the queryParam id
        this.props.getMfpScope({ scopeId: queryParamScopeId }).then((scopeFetch) => {
          if (scopeFetch.type === getScope.rejected.type) {
            // if an error occurs fetching a query param scope, clear the param
            // then clear scope from redux.  This all *must* happen here, as
            // query params cannot be easily modified outside components,
            // and we need to clear redux at the same time as we clear the queryParam,
            // otherwise we create a self-referencing loop that is hard to control
            search.delete('scope');
            setSearch(search);
            this.props.clearMfpScope();
          }
        });
      }
    }
    generateScopeSelector() {
      const { perspective, disableScope } = this.props.tenantConfig;
      const scopeType = perspective && perspective.scopeConfig.type;
      const scopePerspective = pipe(
        getActivePerspective(this.props.routerParams.perspective, this.perspectives),
        getOrElse(() => ({} as BoundEnabledPerspective))
      );
      switch (scopeType) {
        case 'AssortmentScopeSelector':
          return (
            !disableScope && (
              <AssortmentScopeSelector perspective={scopePerspective} routerLocation={this.props.routerLocation} />
            )
          );
        // add more case if it need
        //default to Assortment Scope Selector
        case 'MfpScopeSelector':
          // TODO do something with the loading prop here
          return !disableScope && <MfpScopeSelector loading={false} />;
        // add more case if it need
        //default to Assortment Scope Selector
        default:
          return (
            !disableScope && (
              <AssortmentScopeSelector perspective={scopePerspective} routerLocation={this.props.routerLocation} />
            )
          );
      }
    }
    generateHeaderBar() {
      const { perspective, disableFilters, disableScope } = this.props.tenantConfig;
      const headerBarType = perspective && perspective.headerbar.type;
      switch (headerBarType) {
        case 'AssortmentHeaderbar':
          return !disableScope && !disableFilters && <Headerbar />;
        // add more case if it need
        //default to Assortment HeaderBar
        case 'MfpHeaderbar':
          // @ts-ignore
          return !disableScope && !disableFilters && <MfpScopebar />;
        // add more case if it need
        //default to Assortment HeaderBar
        default:
          return !disableScope && !disableFilters && <Headerbar />;
      }
    }
    render() {
      const { disableScope } = this.props.tenantConfig;
      const { viewLinks } = this.props;
      const perspective = pipe(
        getActivePerspective(this.props.routerParams.perspective, this.perspectives),
        getOrElse(() => ({} as BoundEnabledPerspective))
      );
      const perspectiveLookupKey = getPerspectiveLookupKey(perspective);
      if (isEmpty(perspectiveLookupKey)) {
        return <Navigate to="/" />;
      }

      const view = this.bindings[perspectiveLookupKey];
      const tabLinks = this.tabRouteLinks[perspectiveLookupKey];
      let overflow = getActiveComponentOverflow(zipper(view), this.props.routerLocation.pathname);
      let topNavBar, clearfix, printStyle;
      let mainClass = styles.mainClass;
      let viewPortClass = viewPortStyle;

      if (!this.props.isPrintMode && !isNil(viewLinks)) {
        topNavBar = (
          <TopNavbar
            routeLinks={tabLinks}
            viewLinks={viewLinks}
            bindings={this.bindings}
            perspective={perspective}
            location={this.props.routerLocation}
          />
        );
      } else {
        const width = this.props.printWidth || 'initial';
        const height = this.props.printHeight || 'initial';
        overflow = 'unset';
        viewPortClass = '';
        printStyle = { display: 'block', width, height };
        clearfix = <div className={'clearfix'} />;
        mainClass = styles.mainClassPrintable;
      }

      let content = <div />;
      if (this.props.validScope || disableScope) {
        content = (
          <div key={'content'} className={mainClass} style={{ overflow: overflow }}>
            <Routes>{getContentArea(zipper(view))}</Routes>
          </div>
        );
      }
      return (
        <StylesProvider jss={jss} generateClassName={generateClassName} key={perspectiveLookupKey}>
          <MuiThemeProvider key={perspectiveLookupKey} theme={muiTheme}>
            <div key="router-container" data-qa="router-container" className={viewPortClass} style={printStyle}>
              {this.generateScopeSelector()}
              {topNavBar}
              {this.generateHeaderBar()}
              <div className={styles.belowHeaderBarsDiv}>
                <Routes>
                  {this.bindings[perspectiveLookupKey].boundTabs
                    .filter((tab) => tab.boundSections && tab.boundSections.length)
                    .map((tab) => {
                      return (
                        <Route
                          key={`${perspectiveLookupKey}-${tab.id}`}
                          // only needs to match tab pathslot to render element
                          path={`${tab.pathSlot}/*`}
                          element={
                            <SideNav
                              className={styles.sidenavClass}
                              sectionName={tab.name}
                              perspective={perspective}
                              tab={tab}
                              routerLocation={this.props.routerLocation}
                            />
                          }
                        />
                      );
                    })}
                </Routes>
                {content}
                {this.props.isPrintMode ? undefined : <RightContainer />}
              </div>
              {clearfix}
            </div>
          </MuiThemeProvider>
        </StylesProvider>
      );
    }
  };
};
