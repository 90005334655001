import { z } from 'zod';
import { UIConfDefn, EnabledTab as UIConfDefnTab, LeftNavSection, ConfDefnTab } from './confdefn';
import { SectionView } from './confdefnComponents';
import { FlowStatus } from './general';
import { Perspective } from './literals';

export interface ViewComponent extends React.ComponentClass<Record<string, unknown>> { }

export enum AppType {
  Assortment = 'Assortment',
  TDAnalysis = 'TDAnalysis',
  Configurator = 'Configurator',
  Allocation = 'Allocation',
  FinancialPlanning = 'FinancialPlanning',
}

export type Perspective = z.infer<typeof Perspective>;
export interface ViewBinding {
  id: string;
  pathSlot: string;
  inPerspectives: readonly Perspective[];
  component: ViewComponent;
  icon: string;
  name: string;
  componentProps?: { [s: string]: unknown };
  hidden?: boolean;
  allowNesting?: boolean;
}

export interface LeftSectionBinding {
  id: string;
  name: string;
  pathSlot: string;
  inPerspectives: readonly Perspective[];
  viewBindings: ViewBinding[];
  icon: string;
}

export interface PathSlotted {
  pathSlot: string;
}

export interface CanBeDisabled {
  disabled: boolean;
}

export interface CanBeHidden {
  hidden?: boolean;
}

export interface BoundView extends PathSlotted, CanBeDisabled, ViewBinding, CanBeHidden {
  id: string;
  icon: string;
  name: string;
  overflow: string;
  // The only thing the component would be able to receive would be the props from the Route match.
  // Since existing components weren't written with this in mind,
  component: ViewComponent;
  componentProps?: { [s: string]: unknown };
  errorView?: boolean;
}

export interface BoundSection extends PathSlotted, CanBeDisabled {
  id: string;
  name: string;
  defaultViewId: string | undefined;
  pathSlot: string;
  disabled: boolean;
  icon: string;
  boundViews: BoundView[];
}

export interface BoundTab extends PathSlotted, CanBeDisabled {
  id: string;
  defaultSectionId: string | undefined;
  name: string;
  disabled: boolean;
  boundSections?: BoundSection[];
  component?: ViewComponent;
}

export interface BoundTenant {
  defaultBoundTab: BoundTab | undefined;
  defaultTabSlot: string | undefined;
  boundTabs: BoundTab[];
  perspective: BoundEnabledPerspective;
}

export interface BoundEnabledPerspective extends PathSlotted {
  title: string;
  appType: AppType;
  iconSrc: string;
  type: 'Perspective';
  /** controls ability to render the floorset dropdowns in the scope selector */
  canScopeWithFloorsets: boolean;
  hidden?: boolean;
}
export interface DisabledBubble {
  title: string;
  iconSrc: string;
  type: 'DisabledBubble';
  hidden?: boolean;
}
export interface BoundPerspectiveAndTenant { perspective: BoundEnabledPerspective | Bubble; tenant: UIConfDefn }
export interface Bubble {
  title: string;
  iconSrc: string;
  type: 'Bubble';
  view: BoundPerspectiveAndTenant[];
  hidden?: boolean;
}
export interface RouteBubble {
  title: string;
  iconSrc: string;
  route: string;
  type: 'RouteBubble';
  hidden?: boolean;
}
export type BoundPerspective = BoundEnabledPerspective | Bubble | DisabledBubble | RouteBubble;

export type TenantView = z.infer<typeof SectionView>;

export interface TenantLeftNavSection extends z.infer<typeof LeftNavSection> { }

export interface TenantTab extends z.infer<typeof UIConfDefnTab> { }

export type ConfDefnTab = z.infer<typeof ConfDefnTab>;

export function tenantTabHasPathSlot(tab: ConfDefnTab): tab is TenantTab {
  return (tab as TenantTab).pathSlot !== undefined;
}

export interface FlowStatus extends z.infer<typeof FlowStatus> { }

export type TenantConfig = UIConfDefn;
